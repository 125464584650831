body {
  background-color: #fefefe;
  margin: 0;
  padding: 0;
}



.App {
  text-align: center;
  min-height: 100vh; /* Ensure the background color covers the full viewport height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fefefe; /* Update background color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34; /* Update text color to contrast with the new background */
}

.App-link {
  color: #61dafb;
}

.font-14{
  font-size: 24px;
}

.required:after {
  content: ' *';
  color: red;
}

.ValidationColor {
  color: red;
  padding-left: 0.625rem;
  padding-top: 0;
  margin-top: 0;
  margin-left: 0.625rem;
}

.P-Update-Success-1 {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 10;
}

.P-Update-Success-2 {
  background-color: white;
  height: 19.625rem;
  width: 20.875rem;
  z-index: 20;
}

.shooraBluehover:hover{
  cursor: pointer;
}

.navbar-custom {
  background-color: #2e2c7d;
}

.white{
  color: white;
}

.shooraBlue {
  color: #2e2c7d;
}

.shooraOrr {
  color: #ff792c;
}

.shooraOrrBg {
  background-color: #ff792c;
}

.shooraBlueBg{
  background-color: #2e2c7d;
}

.dateCol{
  background-color: #182b35;
}

.green{
  color: green;
}

.greyBg{
  background-color: #eeeef6;
}

.btn-primary{
  background-color: #2e2c7d;
  border: none;
}


/* @media (max-width: 767px) {
  .header-contact-btn {
    display: none;
  }
} */

.label-btn {
  border: 0.0625rem solid #0152a3;
  border-radius: 0.5rem;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  color: #0152a3;
  font-size: 1.25rem;
}

.label-btn:hover {
  border: 0.0625rem solid #0152a3;
  border-radius: 0.5rem;
  background-color: #0152a3;
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 1.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.label-background-color {
  background-color: #ff792c !important;
  border: 0;
}


.col-white {
  color: white !important;
}

.modal-bg {
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.4;
}

.testInput > input[type="tel"]{
  margin-right: 10px !important;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 35px !important;
  height: 35px !important;
}
